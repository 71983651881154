import { useMutation, useQuery } from '@tanstack/vue-query'
import type {
  ExportSearchJson,
  IExportLeasesRequest,
  IExportLeasesToEmailRequest,
  IExportLeaseSummary,
  IQueryLeasesRequest,
  ExportLeasesSearchRequest,
  ExportLeasesRequest,
} from './types'
import type { ILeaseExportGroup } from '@export'
import {
  GroupByOption,
  SortColumn,
  SortDirection,
  type ISearchPropertyLeasePortfolioRequest,
} from '@portfolio'
import { toast } from 'vue3-toastify'

export const useApiExportFields = (divisionId: string) => {
  return useQuery({
    queryKey: ['divisions', divisionId, 'leases', 'export', 'fields'],
    queryFn: () =>
      fetchAPI<ILeaseExportGroup[]>(
        `/divisions/${divisionId}/leases/export/fields`,
      ),
  })
}

export const useApiExport = (divisionId: string) => {
  const getLeasesWithQuery = useMutation({
    mutationFn: (payload: IQueryLeasesRequest) =>
      fetchAPI<IExportLeaseSummary[]>(
        `/divisions/${divisionId}/leases/export/query`,
        {
          method: 'POST',
          body: payload,
        },
      ),
  })

  const exportLeasesToXlsx = useMutation({
    mutationFn: (payload: IExportLeasesRequest) =>
      fetchAPI(`/divisions/${divisionId}/leases/export/xlsx`, {
        method: 'POST',
        body: payload,
      }),
  })

  const exportLeasesToEmail = useMutation({
    mutationFn: (payload: IExportLeasesToEmailRequest) =>
      fetchAPI(`/divisions/${divisionId}/leases/export/email`, {
        method: 'POST',
        body: payload,
      }),
  })

  return {
    getLeasesWithQuery,
    exportLeasesToXlsx,
    exportLeasesToEmail,
    isExportingLeasesToXlsx: exportLeasesToXlsx.isPending,
  }
}

export async function exportLeaseToXlsx(
  divisionId: string,
  leaseId: string,
  leaseName: string,
) {
  const dayjs = useDayjs()
  const id = `${leaseId}-export`
  const button = document.getElementById(id)
  button?.classList.add('loading')

  return fetchAPI<any>(
    `/divisions/${divisionId}/leases/${leaseId}/export/xlsx`,
    {
      method: 'POST',
      body: {
        fieldIds: [],
      },
    },
  )
    .then((response) => {
      const blob = new Blob([response], { type: 'application/xlsx' })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      // TODO: Can't get the date format for export
      link.download = `${leaseName} - ${dayjs().format(
        getLeaseDateFormat(),
      )}.xlsx`
      link.click()
      URL.revokeObjectURL(link.href)
      toast.success('XLS downloaded')
    })
    .finally(() => {
      button?.classList.remove('loading')
    })
}
// ISearchPropertyLeasePortfolioRequest | ISearchPropertyLeasePortfolioRequest
export interface ExportLeasePayload {
  fieldIds: string[]
  filters?: ISearchPropertyLeasePortfolioRequest | null
  leaseIds?: string[] | undefined
}

export async function exportLeasesToXlsx(
  divisionId: string,
  payload: ExportLeasePayload,
) {
  const dayjs = useDayjs()

  return fetchAPI<any>(createURI(divisionId, 'xlsx', payload), {
    method: 'POST',
    body: createPayload(payload),
  }).then((response) => {
    const blob = new Blob([response], { type: 'application/xlsx' })
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    // TODO: Can't get the date format for export
    link.download = `Lease report - ${dayjs().format(getLeaseDateFormat())}.xlsx`
    link.click()
    URL.revokeObjectURL(link.href)
    toast.success('XLS downloaded')
  })
}

export const useApiReports = (divisionId: string) => {
  const payload = ref<ExportLeasePayload>({
    fieldIds: [],
    filters: {
      groupBy: GroupByOption.NONE,
      sort: SortColumn.NAME,
      direction: SortDirection.ASCENDING,
    },
    leaseIds: [],
  })
  const _parsedPayload = computed(() => ({
    fieldIds: payload.value.fieldIds,
    filters: parseFilters(payload.value.filters),
    leaseIds: payload.value.leaseIds,
  }))
  const query = useQuery({
    queryKey: ['useApiReports', divisionId, 'json', _parsedPayload],
    queryFn: async ({ signal }) => {
      return fetchAPI<ExportSearchJson>(
        createURI(divisionId, 'json', _parsedPayload.value),
        {
          method: 'POST',
          body: createPayload(_parsedPayload.value),
          signal,
        },
      ).then((response) => {
        response.columns = response.columns.filter(
          (column) => column.value.toLowerCase() !== 'id',
        )
        return response
      })
    },
  })

  return {
    ...query,
    payload,
  }
}

function createPayload({
  fieldIds,
  filters,
  leaseIds,
}: ExportLeasePayload): ExportLeasesRequest | ExportLeasesSearchRequest {
  if (leaseIds && leaseIds.length > 0) {
    return { fieldIds, leaseIds }
  }
  // TODO: fix filters
  return { fieldIds, search: filters }
}

function createURI(
  divisionId: string,
  type: 'json' | 'xlsx',
  { leaseIds }: ExportLeasePayload,
): string {
  if (leaseIds && leaseIds.length > 0) {
    return `/divisions/${divisionId}/leases/export/${type}`
  }
  return `/divisions/${divisionId}/leases/export/${type}/search`
}
